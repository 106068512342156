import React from 'react';

const IsLoading = () => {
  return (
    <div className="flex h-full w-full items-center justify-center bg-slate-700">
      <div className="animate-pulse text-center">読み込み中...</div>
    </div>
  );
};

export default IsLoading;
