import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import IsLoading from '../components/ui/IsLoading';

const AppRoutes = () => {
  const Map = lazy(() => import('../pages/Map'));
  const Episode = lazy(() => import('../pages/Episode'));
  const Admin = lazy(() => import('../pages/Admin'));

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<IsLoading />}>
            <Map />
          </Suspense>
        }
      />
      <Route
        path="/episode/:episodeId"
        element={
          <Suspense fallback={<IsLoading />}>
            <Episode />
          </Suspense>
        }
      />
      <Route
        path="/admin"
        element={
          <Suspense fallback={<IsLoading />}>
            <Admin />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
