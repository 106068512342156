import { LoadScript } from '@react-google-maps/api';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import Header from './components/ui/Header';
import MainFrame from './components/ui/MainFrame';
import CloudFunctionActivation from './components/util/CloudFunctionActivation';
import AppProvider from './providers/AppProvider';
import AppRoutes from './routes/AppRoutes';

const App = () => {
  const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  // AppProviderはcontextAPIをまとめたもの
  // AppRoutesはルーティングをまとめたもの
  return (
    <MainFrame>
      <AppProvider>
        <LoadScript googleMapsApiKey={googleMapApiKey}>
          <Header />
          <AppRoutes />
          <Toaster />
          <CloudFunctionActivation />
        </LoadScript>
      </AppProvider>
    </MainFrame>
  );
};

export default App;
