import React from 'react';
import { EpisodeProvider } from '../common/context/episode.context';
import { UserAuthProvider } from '../common/context/userAuth.context';
import { UserLocationProvider } from '../common/context/userLoaction.context';
import { UserProfileProvider } from '../common/context/userProfile.context';

const AppProvider = ({ children }) => {
  return (
    <UserAuthProvider>
      <UserProfileProvider>
        <EpisodeProvider>
          <UserLocationProvider>{children}</UserLocationProvider>
        </EpisodeProvider>
      </UserProfileProvider>
    </UserAuthProvider>
  );
};

export default AppProvider;
