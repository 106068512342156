import { collection, onSnapshot } from 'firebase/firestore';
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { firestore } from '../lib/firebase';
import { useUserAuth } from './userAuth.context';

const EpisodeContext = createContext();

export const EpisodeProvider = ({ children }) => {
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [episodes, setEpisodes] = useState(null);
  const [activated, setActivated] = useState(false);
  const userId = useUserAuth();

  useEffect(() => {
    let unsub;

    if (userId) {
      const ref = collection(firestore, 'episodes');

      unsub = onSnapshot(ref, snap => {
        const dataArray = snap.docs
          .map(doc => {
            return doc.data();
          })
          .sort((first, second) => {
            if (first.createdAt < second.createdAt) return 1;
            return -1;
          });
        console.log('episodes', dataArray);
        setEpisodes(dataArray);
      });
    }

    return () => {
      if (unsub) return unsub();
      return null;
    };
  }, [userId]);

  const value = useMemo(
    () => ({
      selectedEpisode,
      setSelectedEpisode,
      episodes,
      activated,
      setActivated,
    }),
    [selectedEpisode, episodes, activated],
  );

  return (
    <EpisodeContext.Provider value={value}>{children}</EpisodeContext.Provider>
  );
};

export const useEpisode = () => useContext(EpisodeContext);
