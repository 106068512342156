import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { auth, firestore } from '../lib/firebase';

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // 匿名認証でアカウントが存在するか（過去にアクセスしてアカウントを作ったことがあるか）を確認
    const authUnsub = onAuthStateChanged(auth, async authObj => {
      // アカウントがある場合はuidをセット
      if (authObj) {
        setUserId(authObj.uid);
      } else {
        // ない場合はfirestoreのusersに新しいuserを生成
        try {
          const userCredential = await signInAnonymously(auth);

          const { uid } = userCredential.user;
          const ref = doc(firestore, `users/${uid}`);

          await setDoc(ref, {
            uid,
            userRole: 'user',
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
          setUserId(uid);
        } catch (error) {
          console.error(error);
        }
      }
    });

    return () => {
      authUnsub();
    };
  }, []);

  return (
    <UserAuthContext.Provider value={userId}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext);
