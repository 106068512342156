import React, { createContext, useState, useContext, useEffect } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { useUserAuth } from './userAuth.context';
import { firestore } from '../lib/firebase';

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const userId = useUserAuth();

  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    let unsub;

    // firestoreのusersを見て、該当するアカウントデータをセット
    // onSnapshotにすることで変更があったら随時セット
    if (userId) {
      const ref = doc(firestore, `users/${userId}`);
      console.log('uid', userId);

      unsub = onSnapshot(
        ref,
        snap => {
          if (snap.data()) {
            console.log('userProfile', snap.data());
            setUserProfile(snap.data());
          }
        },
        error => {
          console.error(error);
        },
      );
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [userId]);

  return (
    <UserProfileContext.Provider value={userProfile}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => useContext(UserProfileContext);
