import React, { useState } from 'react';

const MainFrame = ({ children }) => {
  return (
    <div className="bodyWrap h-screen w-screen relative">
      <div className="pageArea">{children}</div>
    </div>
  );
};

export default MainFrame;
