import { useEffect } from 'react';
import { useEpisode } from '../../common/context/episode.context';

const CloudFunctionActivation = () => {
  const { activated, setActivated } = useEpisode();

  useEffect(() => {
    if (activated === false) {
      const sendData = {
        sentence: 'テスト',
      };

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      };
      setActivated(true);

      fetch(
        'https://asia-northeast1-lucid-timing-365407.cloudfunctions.net/function-kaii/hello_world',
        {
          method: 'POST',
          headers,
          body: JSON.stringify(sendData),
        },
      )
        .then(async response => {
          const responseData = await response.json();
          const array = responseData.prediction[0];
          console.log('cloud function activated', array);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [activated, setActivated]);

  return null;
};

export default CloudFunctionActivation;
