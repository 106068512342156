import React from 'react';
import { useLocation, useNavigate } from 'react-router';

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="pageHeader">
      <div className="inner">
        <h1 className="logo">
          <button type="button" onClick={() => navigate('/')} className="h-6">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo02.png`}
              alt="kaii-map"
            />
          </button>
        </h1>
        {pathname.includes('admin') && <div>管理画面</div>}
      </div>
    </div>
  );
};

export default Header;
